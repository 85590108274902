import { Box, Stack, Button, Typography, Menu, Link } from '@mui/material';
import Logo from '../assets/logo/sleekflow-logo-full.svg';
import Icon from '@/components/Icon';
import { useTranslation } from 'react-i18next';
import {
  getLanguageList,
  LanguageOptions,
} from '@/components/AccountSettingsMenu/SwitchLanguagePanel';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useMenuAnchor } from '@/hooks/useMenuAnchor';

export default function UnAuthenticatedLayout() {
  const { t, i18n } = useTranslation();
  const { anchorEl, open, handleAnchorClick, handleAnchorClose } =
    useMenuAnchor();
  const currentLanguage = getLanguageList(t).find(
    (lang) => lang.lang === i18n.language,
  );
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=0.1" />
      </Helmet>
      <Stack height="100svh">
        <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            padding: '24px 65px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box>
              <img src={Logo} alt="SleekFlow" height={23} />
            </Box>
            <Stack direction="row" alignItems="center">
              <Button
                variant="text"
                startIcon={<Icon icon="globe" size={16} />}
                endIcon={
                  <Icon icon={open ? 'chevron-up' : 'chevron-down'} size={16} />
                }
                onClick={handleAnchorClick}
                sx={{ px: '12px' }}
              >
                <Typography variant="button1">
                  {currentLanguage?.label}
                </Typography>
              </Button>
            </Stack>
            <Menu open={open} anchorEl={anchorEl} onClose={handleAnchorClose}>
              <LanguageOptions sx={{ width: '260px' }} />
            </Menu>
          </Box>
          <Outlet />
          <Box
            component="footer"
            sx={{
              mt: 'auto',
              display: 'flex',
              gap: 2,
              padding: '0 80px',
            }}
          >
            <Link href="/terms" underline="none">
              <Typography
                variant="link2"
                sx={{
                  color: 'darkBlue.70',
                  fontWeight: 600,
                  fontSize: '12px',
                  mr: 1.5,
                }}
              >
                {t('footer.terms', { defaultValue: 'Terms of Service' })}
              </Typography>
            </Link>
            <Link href="/privacy" underline="none">
              <Typography
                variant="link2"
                sx={{ color: 'darkBlue.70', fontWeight: 600, fontSize: '12px' }}
              >
                {t('footer.privacy', { defaultValue: 'Privacy Policy' })}
              </Typography>
            </Link>
          </Box>
        </Box>
      </Stack>
    </>
  );
}
