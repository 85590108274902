import { uuid4 } from '@sentry/utils';
import {
  ConversationApi,
  ConversationMessageApi,
} from '@sleekflow/sleekflow-core-typescript-rxjs-apis';
import type { TravisBackendMessageDomainViewModelsConversationMessageResponseViewModel } from '@sleekflow/sleekflow-core-typescript-rxjs-apis/dist/models';
import { liveQuery } from 'dexie';
import { inject, injectable } from 'inversify';
import flatten from 'lodash/flatten';
import {
  combineLatest,
  concatMap,
  filter,
  finalize,
  from,
  groupBy,
  map,
  mergeMap,
  Observable,
  of,
  repeat,
  shareReplay,
  Subject,
  switchMap,
  take,
  throwError,
  timer,
  toArray,
} from 'rxjs';
import { ajax, AjaxError } from 'rxjs/ajax';

import { ExtendedMessageType } from '@/api/types';
import { AUDIO_TYPES } from '@/pages/Inbox/ConversationInput';
import { MessagingChannel, Staff } from '@/services/companies/company.service';
import type { UnifiedMessage } from '@/services/conversation-inputs/my-conversation-input-view-model';
import { ConversationMessageWrapperManagerService } from '@/services/conversation-messages/managers/conversation-message-wrapper-manager.service';
import { I18nService } from '@/services/i18n/i18n.service';
import { LogService } from '@/services/logs/log.service';
import { OnlineManagerService } from '@/services/online-manager/online-manager.service';
import { DexieService } from '@/services/persistences/dexie-service';
import { SendingMessage } from '@/services/persistences/sf-dexie';
import { RxjsUtils } from '@/services/rxjs-utils/rxjs-utils';
import { ToastMessagesService } from '@/services/toast-messages/toast-messages.service';
import { UserService } from '@/services/user.service';

@injectable()
export class SendingConversationMessageManager {
  private readonly processingMessageChecksum: {
    [messageChecksum: string]: boolean;
  } = {};
  private readonly sentMessage$$ =
    new Subject<TravisBackendMessageDomainViewModelsConversationMessageResponseViewModel>();

  constructor(
    @inject(ConversationApi)
    private conversationApi: ConversationApi,
    @inject(UserService)
    private userService: UserService,
    @inject(ConversationMessageApi)
    private conversationMessageApi: ConversationMessageApi,
    @inject(OnlineManagerService)
    private onlineManagerService: OnlineManagerService,
    @inject(DexieService) private dexieService: DexieService,
    @inject(ConversationMessageWrapperManagerService)
    private conversationMessageWrapperManagerService: ConversationMessageWrapperManagerService,
    @inject(LogService)
    private logService: LogService,
    @inject(ToastMessagesService)
    private toastMessagesService: ToastMessagesService,
    @inject(I18nService)
    private i18nService: I18nService,
  ) {
    // every 1 second send one message per conversation
    setInterval(async () => {
      const failedSendingMessages = await this.dexieService
        .getDb()
        .sendingMessage.where({
          status: 'Failed',
        })
        .toArray();

      of(failedSendingMessages)
        .pipe(take(1))
        .subscribe({
          next: (messages) => {
            for (const message of messages) {
              this.conversationMessageWrapperManagerService
                .getConversationMessageWrapper(message.messageChecksum)
                ?.onNextStatus('Failed');

              this.dexieService
                .getDb()
                .sendingMessage.delete([
                  message.conversationId,
                  message.messageChecksum,
                ]);
            }
          },
        });

      const allQueuedSendingMessages = await this.dexieService
        .getDb()
        .sendingMessage.where({
          status: 'Queued',
        })
        .toArray();

      // Older than 1 hour, Clear to prevent the message from being stuck in the queue
      const tooOldSendingMessages = allQueuedSendingMessages.filter(
        (message) =>
          message.status === 'Queued' &&
          message.createdAt < new Date(Date.now() - 6 * 60 * 60 * 1000),
      );
      if (tooOldSendingMessages.length > 0) {
        await this.dexieService
          .getDb()
          .sendingMessage.bulkDelete(
            tooOldSendingMessages.map((message) => [
              message.conversationId,
              message.messageChecksum,
            ]),
          );

        return;
      }

      of(allQueuedSendingMessages)
        .pipe(
          take(1),

          // Group by conversationId, then send the message in the same conversation sequentially
          mergeMap((messages) =>
            from(messages).pipe(groupBy((message) => message.conversationId)),
          ),
          mergeMap((groupedMessages) =>
            groupedMessages.pipe(
              toArray(),
              map((messages) => [
                messages.sort((m1, m2) => {
                  // earliest first
                  return m1.createdAt.getTime() - m2.createdAt.getTime();
                })[0],
              ]),
            ),
          ),
          mergeMap((messages) => from(messages)),
          concatMap((message) => {
            return this.userService.getMyStaff$().pipe(
              map((staff) => {
                return [message, staff] as const;
              }),
            );
          }),
        )
        .subscribe(([sendingMessage, myStaff]) => {
          try {
            const {
              messageGroupName,
              messagingChannel,
              unifiedMessage,
              conversationId,
              messageChecksum,
              scheduleSentAt,
            } = sendingMessage;

            if (
              this.processingMessageChecksum[messageChecksum] !== undefined &&
              this.processingMessageChecksum[messageChecksum] === true
            ) {
              this.logService.log(
                'Message is already being processed',
                messageChecksum,
              );

              return;
            }

            this.processingMessageChecksum[messageChecksum] = true;

            this.sendMessage$(
              sendingMessage,
              myStaff,
              messagingChannel,
              unifiedMessage,
              conversationId,
              messageChecksum,
              messageGroupName,
              scheduleSentAt,
            )
              .pipe(
                take(1),
                finalize(() => {
                  this.processingMessageChecksum[messageChecksum] = false;
                }),
              )
              .subscribe({
                next: async (tuple) => {
                  tuple[2].forEach((resp) => {
                    this.sentMessage$$.next(resp);
                  });

                  await this.dexieService
                    .getDb()
                    .sendingMessage.delete([
                      sendingMessage.conversationId,
                      sendingMessage.messageChecksum,
                    ]);
                },
                error: async (e) => {
                  if (e.name === 'AjaxError') {
                    const castedError = e as AjaxError;

                    // network error update the message status back to queued then continue the observable to keep it alive
                    if (castedError.status === 0) {
                      await this.dexieService
                        .getDb()
                        .sendingMessage.update(
                          [
                            sendingMessage.conversationId,
                            sendingMessage.messageChecksum,
                          ],
                          {
                            status: 'Queued',
                          },
                        )
                        .then(
                          () => {
                            this.logService.error(
                              'Message status changed to "Queued" due to network error',
                              unifiedMessage,
                            );
                          },
                          (reason) => {
                            this.logService.error(
                              'Message status unable to change to "Queued" due to dexie error',
                              reason,
                            );
                          },
                        );

                      return;
                    }

                    if (castedError.status === 400) {
                      this.logService.error(
                        'Unable to send message due to error',
                        unifiedMessage,
                        castedError.response,
                      );

                      await this.dexieService
                        .getDb()
                        .sendingMessage.update(
                          [
                            sendingMessage.conversationId,
                            sendingMessage.messageChecksum,
                          ],
                          { status: 'Failed' },
                        )
                        .then(
                          () => {
                            this.logService.error(
                              'Message status changed to "Failed" due to error',
                              unifiedMessage,
                            );
                          },
                          (reason) => {
                            this.logService.error(
                              'Message status unable to change to "Failed" due to dexie error',
                              reason,
                            );
                          },
                        );

                      return;
                    }
                  }

                  await this.dexieService
                    .getDb()
                    .sendingMessage.update(
                      [
                        sendingMessage.conversationId,
                        sendingMessage.messageChecksum,
                      ],
                      { status: 'Failed' },
                    )
                    .then(
                      () => {
                        this.logService.error(
                          'Message status changed to "Failed" due to error',
                          unifiedMessage,
                        );
                      },
                      (reason) => {
                        this.logService.error(
                          'Message status unable to change to "Failed" due to dexie error',
                          reason,
                        );
                      },
                    );
                },
              });
          } catch (e) {
            this.logService.error(e);
          }
        });
    }, 1000);
  }

  private sendMessage$(
    sendingMessage: SendingMessage,
    myStaff: Staff,
    messagingChannel: '' | MessagingChannel,
    unifiedMessage: UnifiedMessage,
    conversationId: string,
    messageChecksum: string,
    messageGroupName: string,
    scheduleSentAt: string | undefined,
  ) {
    const commonRetryPipeOperators = (
      source: Observable<
        TravisBackendMessageDomainViewModelsConversationMessageResponseViewModel[]
      >,
    ) =>
      source.pipe(
        map(
          (
            resp: TravisBackendMessageDomainViewModelsConversationMessageResponseViewModel[],
          ) => {
            return [sendingMessage, myStaff, resp] as const;
          },
        ),

        // retry 3 times until failure
        RxjsUtils.getRetryAPIRequest({
          delay: (error) => {
            if (error.response.code) {
              if (this.handleKnownError(error.response.code)) {
                return throwError(() => error.response.code);
              }
            }

            if (error.status === 0) {
              // Network error occurred and end the observable
              return throwError(() => error);
            }
            // delay 1000ms before retry
            return timer(1000);
          },
        }),
      );

    if (messagingChannel !== '' && messagingChannel.channelType === 'note') {
      // Need to remove all the markup from react-mentions '@[__display__](__id__)' in the text
      // and finally get only the display text
      const text = unifiedMessage.text.replace(/@\[(.*?)]\(.*?\)/g, '$1');

      // TODO: Combine savedReply and attachments conditions when working on DEVS-9611,
      // Need backend support to allow files, fileUrls and fileNames fields in request body simultaneously
      if (unifiedMessage.savedReply) {
        return this.conversationApi
          .conversationNoteConversationIdPost({
            conversationId: conversationId,
            messageChecksum,
            channel: 'note',
            messageGroupName,
            assigneeId: unifiedMessage.mentionedStaffIdentityId,
            messageType: 'file',
            messageContent: text,
            fileUrls:
              unifiedMessage.savedReply.attachment &&
              unifiedMessage.savedReply.attachment.url
                ? [unifiedMessage.savedReply.attachment.url]
                : [],
            fileNames:
              unifiedMessage.savedReply.attachment &&
              unifiedMessage.savedReply.attachment.filename
                ? [unifiedMessage.savedReply.attachment.filename]
                : [],
          })
          .pipe(
            map((resp) => [resp]),
            commonRetryPipeOperators,
          );
      }

      if (unifiedMessage.attachments) {
        return this.conversationApi
          .conversationNoteConversationIdPost({
            conversationId: conversationId,
            messageChecksum,
            channel: 'note',
            messageGroupName,
            assigneeId: unifiedMessage.mentionedStaffIdentityId,
            messageType: 'file',
            messageContent: text,
            files: unifiedMessage.attachments.map((attachment) => {
              return attachment.blob;
            }),
          })
          .pipe(
            map((resp) => [resp]),
            commonRetryPipeOperators,
          );
      }

      if (unifiedMessage.audio) {
        if (!unifiedMessage.audio) {
          throw new Error('Audio is not selected');
        }

        const getAudioFile = () => {
          const audioBlob = unifiedMessage.audio!;

          const blobType = audioBlob.type;

          const fileExtension = AUDIO_TYPES[blobType]?.extension ?? 'bin';
          const audioFile = new File([audioBlob], `voice.${fileExtension}`, {
            type: blobType,
          });
          return audioFile;
        };

        return this.conversationApi
          .conversationNoteConversationIdPost({
            conversationId: conversationId,
            messageChecksum,
            channel: 'note',
            messageGroupName,
            assigneeId: unifiedMessage.mentionedStaffIdentityId,

            messageType: 'file',
            files: [getAudioFile()],
          })
          .pipe(
            map((resp) => [resp]),
            commonRetryPipeOperators,
          );
      }

      return this.conversationApi
        .conversationNoteConversationIdPost({
          conversationId: conversationId,
          messageChecksum,
          channel: 'note',
          messageGroupName,
          assigneeId: unifiedMessage.mentionedStaffIdentityId,

          messageType: 'text',
          messageContent: text,
        })
        .pipe(
          map((resp) => [resp]),
          commonRetryPipeOperators,
        );
    }

    if (unifiedMessage.savedReply) {
      if (messagingChannel == '') {
        throw new Error('Messaging channel is not selected');
      }

      return this.conversationMessageApi
        .conversationMessagesSendMessagePost({
          travisBackendMessageDomainViewModelsExtendedConversationMessageViewModel:
            {
              conversationId: conversationId,
              messageChecksum,
              channel: messagingChannel.channelType,
              channelIdentityId:
                messagingChannel.channelIdentityId === null
                  ? undefined
                  : messagingChannel.channelIdentityId,
              files: [],
              quotedMsgId: unifiedMessage.quotedMessageId,
              messageType: 'text',
              fileURLs:
                unifiedMessage.savedReply.attachment &&
                unifiedMessage.savedReply.attachment.url
                  ? [unifiedMessage.savedReply.attachment.url]
                  : [],
              messageContent: unifiedMessage.text,

              // Only when there is attachment, we need to send quickReplyId
              quickReplyId: unifiedMessage.savedReply.attachment
                ? unifiedMessage.savedReply.id
                : undefined,
              scheduleSentAt: scheduleSentAt,
              messageTag: unifiedMessage.facebook?.messageTag,
            },
        })
        .pipe(commonRetryPipeOperators);
    }

    if (
      (unifiedMessage.commerceHub.sharedLineItems &&
        unifiedMessage.commerceHub.sharedLineItems.length > 0) ||
      (unifiedMessage.shopify.sharedLineItems &&
        unifiedMessage.shopify.sharedLineItems.length > 0)
    ) {
      if (messagingChannel == '') {
        throw new Error('Messaging channel is not selected');
      }

      const sharedLineItems =
        unifiedMessage.commerceHub.sharedLineItems ||
        unifiedMessage.shopify.sharedLineItems;

      const observables = sharedLineItems!.map((lineItem, idx) => {
        if (lineItem.coverImageUrl) {
          return ajax({
            url: lineItem.coverImageUrl,
            responseType: 'blob',
          }).pipe(
            map((blob) => {
              return blob.response as Blob;
            }),
            switchMap((blob) => {
              return this.conversationMessageApi
                .conversationMessagesSendFileMessagePost({
                  conversationId: conversationId,
                  messageChecksum: idx === 0 ? messageChecksum : uuid4(),
                  channel: messagingChannel.channelType,
                  channelIdentityId:
                    messagingChannel.channelIdentityId === null
                      ? undefined
                      : messagingChannel.channelIdentityId,
                  messageType: 'file',
                  messageContent: lineItem.messagePreview,
                  files: [blob],
                  scheduleSentAt: scheduleSentAt,
                  messageTag: unifiedMessage.facebook?.messageTag,
                })
                .pipe(commonRetryPipeOperators);
            }),
          );
        }

        return this.conversationMessageApi
          .conversationMessagesSendMessagePost({
            travisBackendMessageDomainViewModelsExtendedConversationMessageViewModel:
              {
                conversationId: conversationId,
                messageChecksum: idx === 0 ? messageChecksum : uuid4(),
                channel: messagingChannel.channelType,
                channelIdentityId:
                  messagingChannel.channelIdentityId === null
                    ? undefined
                    : messagingChannel.channelIdentityId,
                messageType: 'file',
                messageContent: lineItem.messagePreview,
                fileURLs: [lineItem.coverImageUrl],
                fileNames: [uuid4()],
                scheduleSentAt: scheduleSentAt,
                messageTag: unifiedMessage.facebook?.messageTag,
              },
          })
          .pipe(commonRetryPipeOperators);
      });

      return combineLatest(observables).pipe(
        map((tuples) => {
          const respArrays = tuples.map((tuple) => tuple[2]);
          const arr = flatten(respArrays);

          return [sendingMessage, myStaff, arr] as const;
        }),
      );
    }

    if (unifiedMessage.whatsappCloudApi?.templateMessage) {
      if (messagingChannel == '') {
        throw new Error('Messaging channel is not selected');
      }

      return this.conversationMessageApi
        .conversationMessagesSendMessagePost({
          travisBackendMessageDomainViewModelsExtendedConversationMessageViewModel:
            {
              conversationId: conversationId,
              messageChecksum,
              channel: messagingChannel.channelType,
              channelIdentityId:
                messagingChannel.channelIdentityId === null
                  ? undefined
                  : messagingChannel.channelIdentityId,
              messageType: 'template',
              extendedMessagePayload: {
                channel: messagingChannel.channelType,
                extendedMessageType: 101,
                extendedMessagePayloadDetail: {
                  whatsappCloudApiTemplateMessageObject:
                    unifiedMessage.whatsappCloudApi.templateMessage,
                },
              },
              messageContent: unifiedMessage.text,
              scheduleSentAt: scheduleSentAt,
              messageTag: unifiedMessage.facebook?.messageTag,
            },
        })
        .pipe(commonRetryPipeOperators);
    }

    if (unifiedMessage.whatsapp360Dialog?.templateMessage) {
      if (messagingChannel == '') {
        throw new Error('Messaging channel is not selected');
      }

      return this.conversationMessageApi
        .conversationMessagesSendMessagePost({
          travisBackendMessageDomainViewModelsExtendedConversationMessageViewModel:
            {
              conversationId: conversationId,
              messageChecksum,
              channel: messagingChannel.channelType,
              channelIdentityId:
                messagingChannel.channelIdentityId === null
                  ? undefined
                  : messagingChannel.channelIdentityId,
              messageType: 'template',
              whatsapp360DialogExtendedMessagePayload: {
                whatsapp360DialogTemplateMessage:
                  unifiedMessage.whatsapp360Dialog.templateMessage,
              },
              messageContent: unifiedMessage.text,
              scheduleSentAt: scheduleSentAt,
              messageTag: unifiedMessage.facebook?.messageTag,
            },
        })
        .pipe(commonRetryPipeOperators);
    }

    if (unifiedMessage.whatsappTwilio?.templateMessage) {
      if (messagingChannel == '') {
        throw new Error('Messaging channel is not selected');
      }

      return this.conversationMessageApi
        .conversationMessagesSendMessagePost({
          travisBackendMessageDomainViewModelsExtendedConversationMessageViewModel:
            {
              messageType: 'text',
              messageContent: unifiedMessage.text,
              extendedMessagePayload: {
                extendedMessageType:
                  ExtendedMessageType.WhatsappTwilioContentTemplateMessage,
                extendedMessagePayloadDetail: {
                  whatsappTwilioContentApiObject:
                    unifiedMessage.whatsappTwilio.templateMessage,
                },
              },
              conversationId: conversationId,
              messageChecksum,
              channel: messagingChannel.channelType,
              channelIdentityId:
                messagingChannel.channelIdentityId === null
                  ? undefined
                  : messagingChannel.channelIdentityId,
              scheduleSentAt: scheduleSentAt,
              messageTag: unifiedMessage.facebook?.messageTag,
            },
        })
        .pipe(commonRetryPipeOperators);
    }

    if (unifiedMessage.whatsappCloudApi?.interactiveMessage) {
      if (messagingChannel == '') {
        throw new Error('Messaging channel is not selected');
      }
      return this.conversationMessageApi
        .conversationMessagesSendMessagePost({
          travisBackendMessageDomainViewModelsExtendedConversationMessageViewModel:
            {
              conversationId: conversationId,
              messageChecksum,
              channel: messagingChannel.channelType,
              channelIdentityId:
                messagingChannel.channelIdentityId === null
                  ? undefined
                  : messagingChannel.channelIdentityId,
              messageType: 'interactive',
              extendedMessagePayload: {
                channel: messagingChannel.channelType,
                extendedMessageType: 102,
                extendedMessagePayloadDetail: {
                  whatsappCloudApiInteractiveObject:
                    unifiedMessage.whatsappCloudApi.interactiveMessage,
                },
              },
              messageContent: unifiedMessage.text,
              scheduleSentAt: scheduleSentAt,
              messageTag: unifiedMessage.facebook?.messageTag,
            },
        })
        .pipe(commonRetryPipeOperators);
    }

    if (unifiedMessage.whatsapp360Dialog?.interactiveMessage) {
      if (messagingChannel == '') {
        throw new Error('Messaging channel is not selected');
      }
      return this.conversationMessageApi
        .conversationMessagesSendMessagePost({
          travisBackendMessageDomainViewModelsExtendedConversationMessageViewModel:
            {
              conversationId: conversationId,
              messageChecksum,
              channel: messagingChannel.channelType,
              channelIdentityId:
                messagingChannel.channelIdentityId === null
                  ? undefined
                  : messagingChannel.channelIdentityId,
              messageType: 'interactive',
              whatsapp360DialogExtendedMessagePayload: {
                whatsapp360DialogInteractiveObject:
                  unifiedMessage.whatsapp360Dialog.interactiveMessage,
              },
              messageContent: unifiedMessage.text,
              scheduleSentAt: scheduleSentAt,
              messageTag: unifiedMessage.facebook?.messageTag,
            },
        })
        .pipe(commonRetryPipeOperators);
    }

    if (unifiedMessage.audio) {
      if (messagingChannel == '') {
        throw new Error('Messaging channel is not selected');
      }

      if (!unifiedMessage.audio) {
        throw new Error('Audio is not selected');
      }

      const getAudioFile = () => {
        const audioBlob = unifiedMessage.audio!;

        const blobType = audioBlob.type;

        const fileExtension = AUDIO_TYPES[blobType]?.extension ?? 'bin';
        const audioFile = new File([audioBlob], `voice.${fileExtension}`, {
          type: blobType,
        });
        return audioFile;
      };

      return this.conversationMessageApi
        .conversationMessagesSendFileMessagePost({
          conversationId: conversationId,
          messageChecksum,
          channel: messagingChannel.channelType,
          channelIdentityId:
            messagingChannel.channelIdentityId === null
              ? undefined
              : messagingChannel.channelIdentityId,
          files: [getAudioFile()],
          messageType: 'file',
          messageContent: '',
          scheduleSentAt: scheduleSentAt,
          messageTag: unifiedMessage.facebook?.messageTag,
        })
        .pipe(commonRetryPipeOperators);
    }

    const messageContent = myStaff.shouldShowSenderName
      ? `*${myStaff.firstName} ${myStaff.lastName}*\n${unifiedMessage.text}`
      : '' + unifiedMessage.text;

    if (unifiedMessage.attachments && unifiedMessage.attachments.length > 0) {
      if (messagingChannel == '') {
        throw new Error('Messaging channel is not selected');
      }

      return this.conversationMessageApi
        .conversationMessagesSendFileMessagePost({
          conversationId: conversationId,
          messageChecksum,
          channel: messagingChannel.channelType,
          channelIdentityId:
            messagingChannel.channelIdentityId === null
              ? undefined
              : messagingChannel.channelIdentityId,
          files: unifiedMessage.attachments.map((attachment) => {
            return attachment.blob;
          }),
          messageType: 'file',
          messageContent: messageContent,
          scheduleSentAt: scheduleSentAt,
          messageTag: unifiedMessage.facebook?.messageTag,
        })
        .pipe(commonRetryPipeOperators);
    }

    if (messagingChannel == '') {
      throw new Error('Messaging channel is not selected');
    }

    if (messagingChannel.channelType === 'sms') {
      // Split message into multiple message of 1600 characters if the message is more than 1600 characters,
      // for example if the message is 4096 characters then the message will be split into 1600*2+896
      const messageContentLength = messageContent.length;
      const maxMessageLength = 1599;
      const numberOfMessages = Math.ceil(
        messageContentLength / maxMessageLength,
      );
      const messages = [];
      for (let i = 0; i < numberOfMessages; i++) {
        const start = i * maxMessageLength;
        const end = Math.min(start + maxMessageLength, messageContentLength);
        messages.push(messageContent.slice(start, end));
      }

      return from(messages).pipe(
        concatMap((message, index) => {
          return this.conversationMessageApi
            .conversationMessagesSendMessagePost({
              travisBackendMessageDomainViewModelsExtendedConversationMessageViewModel:
                {
                  conversationId: conversationId,
                  messageChecksum: index === 0 ? messageChecksum : uuid4(),
                  channel: messagingChannel.channelType,
                  channelIdentityId: messagingChannel.channelIdentityId,
                  messageType: 'text',
                  messageContent: message,
                  scheduleSentAt: scheduleSentAt,
                },
            })
            .pipe(commonRetryPipeOperators);
        }),
      );
    }

    return this.conversationMessageApi
      .conversationMessagesSendMessagePost({
        travisBackendMessageDomainViewModelsExtendedConversationMessageViewModel:
          {
            conversationId: conversationId,
            messageChecksum,
            channel: messagingChannel.channelType,
            channelIdentityId:
              messagingChannel.channelIdentityId === null
                ? undefined
                : messagingChannel.channelIdentityId,
            messageType: 'text',
            quotedMsgId: unifiedMessage.quotedMessageId,
            messageContent: messageContent,
            scheduleSentAt: scheduleSentAt,
            messageTag: unifiedMessage.facebook?.messageTag,
            paymentIntentId: unifiedMessage.stripe?.paymentIntentId,
          },
      })
      .pipe(commonRetryPipeOperators);
  }

  public getSendingMessages$(conversationId: string) {
    return of(conversationId).pipe(
      filter((x): x is string => x !== null && x !== undefined && x !== ''),
      switchMap((conversationId) => {
        return from(
          liveQuery(() => {
            return this.dexieService
              .getDb()
              .sendingMessage.filter((m) => {
                return m.conversationId === conversationId;
              })
              .toArray();
          }),
        ).pipe(
          repeat({
            delay: () =>
              this.onlineManagerService.online$.pipe(filter((x) => x)),
          }),
        );
      }),
      shareReplay(),
    );
  }

  public getSentMessage$() {
    return this.sentMessage$$.asObservable();
  }

  public queueSendingMessages(sendingMessages: SendingMessage[]) {
    return this.dexieService.getDb().sendingMessage.bulkAdd(sendingMessages);
  }

  public queueSendingMessage(sendingMessage: SendingMessage) {
    return this.dexieService.getDb().sendingMessage.add(sendingMessage);
  }

  private handleKnownError(errorCode: number) {
    switch (errorCode) {
      case 20000:
        this.toastMessagesService.showToastMessage(
          'error',
          this.i18nService.t(
            'sending-conversation-message-manager.error.invalid-or-empty-default-channel-identity',
            'Invalid or empty default channel identity, please refresh the page and try again',
          ),
        );
        return true;
      case 20001:
        this.toastMessagesService.showToastMessage(
          'error',
          this.i18nService.t(
            'sending-conversation-message-manager.error.invalid-or-empty-default-channel',
            'Invalid or empty default channel, please refresh the page and try again',
          ),
        );
        return true;
      case 20002:
        this.toastMessagesService.showToastMessage(
          'error',
          this.i18nService.t(
            'sending-conversation-message-manager.error.no-default-channel-permission',
            'Channel permission updated, please refresh the page and try again',
          ),
        );
        return true;
      default:
        return false;
    }
  }
}
