import { Box } from '@mui/material';

export default function InvitationLink() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
      }}
    >
      <Box
        sx={(theme) => ({
          height: 'calc(100vh - 180px)',
          minWidth: '640px',
          borderRadius: '16px',
          padding: 5,
          boxShadow: theme.shadow.lg,
        })}
      >
        {/* InvitationLink content*/}
      </Box>
    </Box>
  );
}
